import { Input, Modal } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { importExternalTicketByExternalId } from "../../utils/helpers/ticketHelpers";
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../interfaces/users";
import { toast } from "react-toastify";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { hasMinimumPermissions, hasNoPermission, ModulePermission, userHasPermission } from "../../utils/helpers/userHelpers";

interface ImportTicketModalProps {
  type: "checklist" | "measuring";
  onClose: () => void;
  isOpen: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
}

export const ImportTicketModal = ({
  type,
  onClose,
  isOpen,
  refetch,
}: ImportTicketModalProps) => {
  const [externalId, setExternalId] = useState("");
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state: AuthenticationState) => state);

  const handleClose = () => {
    setExternalId("");
    onClose();
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!userHasPermission(user, {checklist: ModulePermission.ADMIN, measuring: ModulePermission.ADMIN})) return toast.error('Usuário sem permissão para esta funcionalidade')
    if (!externalId) return toast.error("O campo de ID é obrigatório");
    setLoading(true);
    await importExternalTicketByExternalId(
      token,
      externalId,
      type,
      handleClose
    );
    refetch();
    setExternalId("");
    setLoading(false);
  };
  return (
    <Modal
      open={isOpen}
      confirmLoading={loading}
      onCancel={handleClose}
      onOk={handleSubmit}
    >
      <Content>
        <h2>
          Importar ticket de {type === "checklist" ? "Checklist" : "Medição"}
        </h2>
        <label className="text-base text-[#8C8C8C]">
          ID do ticket<span className="text-red-600">*</span>:
        </label>
        <Input
          onChange={(e) => setExternalId(e.target.value)}
          value={externalId}
          className="mb-2"
        />
      </Content>
    </Modal>
  );
};
